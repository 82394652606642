import React, {Component, Suspense} from 'react'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from '../store'
import PrivateRoute from './PrivateRoute'

import App from '../App'
// 配置语言包
import {ConfigProvider} from 'antd'
import zhCN from 'antd/es/locale/zh_CN'

import Loading from './../components/Loading'
import AdminLayout from './AdminLayout'

import WebLayout from './WebLayout'


import Login from '../pages/backend/Login'

// 前台
import FrontendHome from '../pages/frontend/Home'

const FrontendAbout = React.lazy(/* webpackChunkName: "web-about" */ () => import('../pages/frontend/About'))
const WebPost = React.lazy(/* webpackChunkName: "web-post" */ () => import('pages/frontend/Post'))


// 后台管理
const JobClassification = React.lazy(/* webpackChunkName: "job-classification" */ () => import('../pages/backend/JobClassification'))
const Resume = React.lazy(/* webpackChunkName: "resume" */ () => import('../pages/backend/Resume'))
const Job = React.lazy(/* webpackChunkName: "job" */ () => import('../pages/backend/Job'))

const Home = React.lazy(/* webpackChunkName: "home" */ () => import('../pages/backend/Home'))
const UserCenter = React.lazy(/* webpackChunkName: "user-center" */ () => import('../pages/backend/UserCenter'))
const UserSettings = React.lazy(/* webpackChunkName: "user-settings" */ () => import('../pages/backend/UserSettings'))
const UserList = React.lazy(/* webpackChunkName: "user-list" */ () => import('../pages/backend/UserList'))
const UserArchive = React.lazy(/* webpackChunkName: "user-archive" */ () => import('../pages/backend/UserArchive'))

const NoFoundPage = React.lazy(/* webpackChunkName: "no-found-page" */ () => import('../pages/Error/NoFoundPage'))
const NoAuth = React.lazy(/* webpackChunkName: "no-auth" */ () => import('../pages/Error/NoAuth'))
const ServerError = React.lazy(/* webpackChunkName: "server-error" */ () => import('../pages/Error/ServerError'))

const Role = React.lazy(/* webpackChunkName: "role" */ () => import('../pages/backend/Role'))
const Permission = React.lazy(/* webpackChunkName: "permission" */ () => import('../pages/backend/Permission'))

const Examine = React.lazy(/* webpackChunkName: "examine" */ () => import('../pages/backend/Examine'))
const Apply = React.lazy(/* webpackChunkName: "apply" */ () => import('../pages/backend/Apply'))


const SettingDepartment = React.lazy(/* webpackChunkName: "setting-department" */ () => import('../pages/backend/SettingDepartment'))
const SettingCompany = React.lazy(/* webpackChunkName: "setting-company" */ () => import('../pages/backend/SettingCompany'))


const SalesAssessmentPeriodConfiguration = React.lazy(/* webpackChunkName: "SalesAssessmentPeriodConfiguration" */ () => import('../pages/backend/SalesAssessmentPeriodConfiguration'))
const SalesAssessmentAllocation = React.lazy(/* webpackChunkName: "SalesAssessmentAllocation" */ () => import('../pages/backend/SalesAssessmentAllocation'))

const SalesHistoryData = React.lazy(/* webpackChunkName: "SalesHistoryData" */ () => import('../pages/backend/SalesHistoryData'))

const Test = React.lazy(() => import("../pages/backend/Test"))

class ERouter extends Component {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
  }

  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <BrowserRouter>
            <App>
              <Switch>
                <Route path='/admin/login' exact component={Login}/>
                <PrivateRoute path='/admin' component={() =>
                  <AdminLayout >
                    <Suspense fallback={<Loading/>}>
                      <Switch>
                        <Route exact path="/admin/" component={Home} onEnter={() =>{
                        }}/>
                        <Route exact path='/admin/home' component={Home}/>
                        <Route path='/admin/user-center/:id?' exact component={UserCenter}/>
                        <Route path='/admin/user-settings' exact component={UserSettings}/>
                        <Route path='/admin/user-list' exact component={UserList}/>
                        <Route path='/admin/user-archive' exact component={UserArchive}/>
                        <Route path='/admin/role' exact component={Role}/>
                        <Route path='/admin/permission' exact component={Permission}/>
                        <Route path='/admin/examine' exact component={Examine}/>
                        <Route path='/admin/apply' exact component={Apply}/>

                        <Route path='/admin/resume' exact component={Resume}/>
                        <Route path='/admin/job-classification' exact component={JobClassification}/>
                        <Route path='/admin/job' exact component={Job}/>

                        <Route path='/admin/department' exact component={SettingDepartment}/>
                        <Route path='/admin/company' exact component={SettingCompany}/>

                        <Route path='/admin/salesAssessmentPeriodConfiguration' exact
                               component={SalesAssessmentPeriodConfiguration}/>
                        <Route path='/admin/salesAssessmentAllocation' exact component={SalesAssessmentAllocation}/>
                        <Route path='/admin/salesHistoryData' exact component={SalesHistoryData}/>

                        <Route path='/admin/403' exact component={NoAuth}/>
                        <Route path='/admin/404' exact component={NoFoundPage}/>
                        <Route path='/admin/500' exact component={ServerError}/>

                        <Route path='/admin/Test' exact component={Test}/>
                        <Redirect to='/admin/404'/>
                      </Switch>
                    </Suspense>
                  </AdminLayout>
                }/>
                <Route path='/' component={() =>
                  <WebLayout>
                    <Suspense fallback={<Loading/>}>
                      <Switch>
                        <Route exact path="/" component={FrontendHome}/>
                        <Route exact path="/about" component={FrontendAbout}/>
                        <Route exact path="/post" component={WebPost}/>
                        <Route path='/403' exact component={NoAuth}/>
                        <Route path='/404' exact component={NoFoundPage}/>
                        <Route path='/500' exact component={ServerError}/>
                        <Redirect to='/404'/>
                      </Switch>
                    </Suspense>
                  </WebLayout>
                }/>
              </Switch>
            </App>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    )
  }
}


export default ERouter



