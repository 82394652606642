import {combineReducers} from 'redux-immutable'
import {reducer as CommonReducer} from './common'
import {reducer as LoginReducer} from '../pages/backend/Login/store'
import {reducer as UserListReducer} from '../pages/backend/UserList/store'
import {reducer as PermissionReducer} from '../pages/backend/Permission/store'
import {reducer as ApplyReducer} from '../pages/backend/Apply/store'
import {reducer as ExamineReducer} from '../pages/backend/Examine/store'
import {reducer as UserArchiveReducer} from '../pages/backend/UserArchive/store'
import {reducer as RoleReducer} from '../pages/backend/Role/store'
import {reducer as UserSettingsReducer} from '../pages/backend/UserSettings/store'
import {reducer as BindingReducer} from '../pages/backend/UserSettings/components/Binding/store'

import {reducer as SettingCompanyReducer} from '../pages/backend/SettingCompany/store'
import {reducer as SettingDepartmentReducer} from '../pages/backend/SettingDepartment/store'
import {reducer as HomeReducer} from '../pages/backend/Home/store'
import {reducer as SalesAssessmentPeriodConfigurationReducer} from '../pages/backend/SalesAssessmentPeriodConfiguration/store'
import {reducer as SalesAssessmentAllocationReducer} from '../pages/backend/SalesAssessmentAllocation/store'


import {reducer as JobReducer} from '../pages/backend/Job/store'
import {reducer as JobClassificationReducer} from '../pages/backend/JobClassification/store'
import {reducer as ResumeReducer} from '../pages/backend/Resume/store'


import {reducer as WebPostReducer} from 'pages/frontend/Post/store'


const reducer = combineReducers({
  Common: CommonReducer,
  Login: LoginReducer,
  UserList: UserListReducer,
  Role: RoleReducer,
  Apply: ApplyReducer,
  UserArchive: UserArchiveReducer,
  Examine: ExamineReducer,
  Permission: PermissionReducer,
  UserSettings: UserSettingsReducer,
  Job: JobReducer,
  JobClassification: JobClassificationReducer,
  Resume: ResumeReducer,
  WebPost: WebPostReducer,
  SettingCompany: SettingCompanyReducer,
  SettingDepartment: SettingDepartmentReducer,
  Binding: BindingReducer,
  Home: HomeReducer,
  Period: SalesAssessmentPeriodConfigurationReducer,
  TargetType: SalesAssessmentAllocationReducer,
})

export default reducer
