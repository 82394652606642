import React from "react";
import {queryCreate, queryGetColumns} from "../../../../api/TargetType";
import {actionTypes as types} from "./index";
import {queryGetList} from "../../../../api/Sale";
import {fromJS} from "immutable";
import {Tooltip, Typography} from "antd";
import {formatTimeStamp} from "../../../../utils/dateFormat";
import {queryGetList as queryGetHistoryList} from "../../../../api/salesHistoryData";
import {getPercent} from "../../../../utils";

const {Text} = Typography;


export const handleGetData = (params: any, isQueryHistory = false) => {
  return (dispatch: any) => {
    if (Number(params.periodId)) {
      dispatch(setSelectParams(params))
      dispatch(handleGetColumns(params))
      isQueryHistory ? dispatch(handleGetHistoryData(params)) : dispatch(handleGetSaleList(params))
    } else {
      dispatch(setColumns([]))
      dispatch(setSelectParams([]))
      dispatch(setSaleHistoryList([]))
      dispatch(setSaleList([]))
    }
  }
}


export const handleGetColumns = (params: any) => {
  return async (dispatch: any) => {
    const res = await queryGetColumns(params)

    const cols = res.data.map((item: any) => {
      let arr = null
      if (item.periodType === 2 && item.periodInfo.length) {
        arr = item.periodInfo.map((i: any) => {
          return {
            title: <Tooltip title={formatTimeStamp(i.start_at, i.end_at)}>
              <span>{i.title}</span>
            </Tooltip>,
            dataIndex: `cols-${item.id}-${i.id}`,
            key: `cols-${item.id}-${i.id}`,
            editable: true,
          }
        })
        return arr
      }
      return {
        title: item.title,
        dataIndex: `cols-${item.id}-${item.periodInfo.id}`,
        key: `cols-${item.id}-${item.periodInfo.id}`,
        editable: true,
      }
    })
    console.log("cols", cols)
    cols.unshift({
      title: "姓名",
      dataIndex: "",
      width: 130,
      render: (row: any) => {
        return `${row.username} (${row.loginName})`
      }
    })
    dispatch(setColumns([].concat(...cols)))
  }
}

const formatSalesData = (data: any[], isShowHistory = false) => {
  return data.map((item: any) => {
    const {targetInfo} = item
    let aa: any = []
    if (targetInfo && targetInfo.length) {
      aa = targetInfo.map((info: any) => {
        let salesVolume = (info.performance || 0)
        let target = Number(info.target)
        let color = salesVolume < target ? "#f10000" : "#63e257"
        let percentage = getPercent(salesVolume, target) + "%"
        let isHiddenPercentage = info.targetType !== 4 && target !== 0
        let str = target == 0 ? salesVolume : salesVolume + "/" + target

        let value = isShowHistory ?
          <>
            {str}
            {isHiddenPercentage && <Text style={{color}} code> {percentage}</Text>}
          </> : target;

        return {
          [`cols-${info.targetType}-${info.periodId}`]: value,
        }
      })
    }
    return {
      ...Object.assign(item, ...aa),
      key: item.id
    }
  })
}

export const handleGetSaleList = (params: any) => {
  return async (dispatch: any) => {
    const res = await queryGetList(params)

    if (res.data) {
      const data = formatSalesData(res.data)
      console.log(data)
      dispatch(setSaleList(data))
    } else {
      dispatch(setSaleList([]))
    }
  }
}

export const handleGetCrateTarget = (params: any) => {
  return async (dispatch: any, stroe: any) => {
    const res = await queryCreate(params)
    let s = stroe()
    dispatch(handleGetData(s.getIn(["TargetType", "selectParams"]).toJS()))
  }
}

export const handleGetHistoryData = (params: any) => {
  return async (dispatch: any) => {
    const res = await queryGetHistoryList(params)
    if (res.data) {
      const data = formatSalesData(res.data, true)
      dispatch(setSaleHistoryList(data || []))
    }else {
      dispatch(setSaleHistoryList([]))
    }
  }
}

const setSaleList = (data: any) => {
  return {
    type: types.SET_SALE_LIST,
    payload: data
  }
}

const setSaleHistoryList = (data: any) => {
  return {
    type: types.SET_SALE_HISTORY_LIST,
    payload: data
  }
}

const setColumns = (data: any) => {
  return {
    type: types.SET_COLUMNS_LIST,
    payload: data
  }
}

const setSelectParams = (data: any) => {
  return {
    type: types.SET_PARAMS,
    payload: fromJS(data)
  }
}

