import {fromJS} from 'immutable'
import {actionTypes as types} from './index'
import {IAction} from "store/types";


const defaultState = fromJS({
  list: [],
  page: {},
  loading: false
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_BINDING_LIST:
      return setList(state, action.payload)
    default:
      return state
  }
}


const setList = (state: any, payload: any) => {
  return state.merge({
    list: fromJS(payload)
  })
}

