import {actionTypes as types} from './index'
import {post} from "../../../../components/axios";

export const handleQueryList = (params: any = {}) => {
  return (dispatch: Function) => {
    post('v1/resume/index', params).then((data: any) => {
      const {data: list, page} = data
      dispatch(setList(list))
      dispatch(setPage(page))
    })
  }
}

export const handleQueryInfo = (id: number) => {
  return (dispatch: Function) => {
    post('v1/resume/info', {id}).then((data: any) => {
      dispatch(setInfo(data['data']))
    })
  }
}
export const handleSetStatus = (params: any) =>{
  return (dispatch: Function) =>{
    post('/v1/resume/set-status', params).then(() =>{
      dispatch(handleQueryList())
    })
  }
}

export const setList = (params: any) => {
  return {
    type: types.SET_LIST,
    payload: params
  }
}

const setInfo = (params: any) => {
  return {
    type: types.SET_INFO,
    payload: params
  }
}
const setPage = (page: any) => {
  return {
    type: types.SET_PAGE,
    payload: page
  }
}
