import React, {useEffect, useMemo} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {handleCacheData} from "./pages/backend/Login/store/actionCreators";

const App: React.FC = (props: any) => {
  const {dispatch} = props
  // 处理缓存登录信息
  const cbs = useMemo(function tableHeaderCbs() {
    return bindActionCreators({
      handleCacheData
    }, dispatch)
  }, [])

  useEffect(function () {
    cbs.handleCacheData()
  })
  return <>
    {props.children}
  </>
}
const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => {
  return {dispatch}
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
