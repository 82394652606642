import {post} from "../../../../components/axios";
import {actionTypes as types} from './index'
import msg, {MessageTypes} from "../../../../components/Message";

export const handleQueryAvailableList = () => {
  return (dispatch: Function) => {
    post('v1/job-classification/available-list').then((data: any) => {
      dispatch(setAvailableList(data['data']))
    })
  }
}
const setAvailableList = (data: any) => {
  return {
    type: types.SET_AVAILABLE_LIST,
    payload: data
  }
}

export const handleQueryJobList = (cid: number) => {
  return (dispatch: Function) => {
    post('v1/job/available-list', {cid}).then((data: any) => {
      dispatch(setJobList(data['data']))
    })
  }
}

export const handleQueryEduList = () => {
  return (dispatch: Function) => {
    post('/v1/level/edu').then((data: any) => {
      dispatch(setEduList(data['data']))
    })
  }
}

const handleQueryEnglishLevel = () => {
  return (dispatch: Function) => {
    post('/v1/level/english').then((data: any) => {
      const {common, custom} = data.data
      dispatch(setCommonEnglishLevel(common))
      dispatch(setCustomEnglishLevel(custom))
    })
  }
}

export const handleQueryResumeData = (id: number) => {
  return (dispatch: Function, getState: Function) => {
    if (id) {
      const jobList = getState().getIn(['WebPost', 'jobList'])
      jobList.map((item: any) => {
        if (item.get('id') === id) {
          dispatch(setJobInfo(item.toJS()))
        }
        return ""
      })
    }

    dispatch(handleQueryEduList())
    dispatch(handleQueryEnglishLevel())
  }
}


export const handleAddResume = (params: any, changeModalStatus: Function) => {
  return (dispatch: Function) => {
    post('v1/resume/create', params).then((data: any) => {
      msg('简历投递成功', MessageTypes.SUCCESS)
      changeModalStatus(false)
    })
  }
}


const setJobList = (data: any) => {
  return {
    type: types.SET_JOB_LIST,
    payload: data
  }
}

const setJobInfo = (data: any) => {
  return {
    type: types.SET_JOB_INFO,
    payload: data
  }
}

const setEduList = (data: any) => {
  return {
    type: types.SET_EDU_LIST,
    payload: data
  }
}
const setCommonEnglishLevel = (data: any) => {
  return {
    type: types.SET_COMMON_ENGLISH_LEVEL,
    payload: data
  }
}
const setCustomEnglishLevel = (data: any) => {
  return {
    type: types.SET_CUSTOM_ENGLISH_LEVEL,
    payload: data
  }
}

