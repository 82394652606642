import {actionTypes as types} from './index'
import {create, del, info, list, update} from "api/Organizational";
import {buildTree} from "../../../../utils/menuTree";

export const handleQueryList = (params: any = {}) => {
  return (dispatch: Function) => {
    list(params).then((result: any) => {
      const {data} = result
      dispatch(setList(formatData(data)))
    })
  }
}

// 格式化数据
const formatData = (data: any) => {
  return buildTree(data.map((item: any) => {
    return {...item, pid: item.fid}
  }))
}


export const handleQueryValidList = () => {
  return (dispatch: Function) => {
    list({status: 1}).then((result: any) => {
      const {data} = result
      dispatch(setValidList(formatData(data)))
    })
  }
}


export const handleQueryInfo = (id: number) => {
  return (dispatch: Function) => {
    info({id}).then(result => {
      const {data} = result
      dispatch(setInfo(data))
    })
  }
}

export const handleCreate = (params: any) => {
  return (dispatch: Function) => {
    create(params).then(result => {
      dispatch(handleQueryList())
    })
  }
}
export const handleUpdate = (params: any) => {
  return (dispatch: Function) => {
    update(params).then(result => {
      dispatch(handleQueryList())
    })
  }
}

export const handleDel = (id: number) => {
  return (dispatch: Function) => {
    del({id}).then(result => {
      dispatch(handleQueryList())
    })
  }
}
const setValidList = (data: any) => {
  return {
    type: types.SET_VALID_LIST,
    payload: data
  }
}

const setList = (data: any) => {
  return {
    type: types.SET_LIST,
    payload: data
  }
}

export const setInfo = (data: any) => {
  return {
    type: types.SET_INFO,
    payload: data
  }
}
