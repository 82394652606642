import React from 'react'
import {BackTop, Layout} from "antd";
import {connect} from "react-redux";
import WebHeader from './../components/WebLayouts/Header'
import WebFooter from './../components/WebLayouts/Footer'

const {Content} = Layout;

const AdminLayout: React.FC = function (props: any) {


  return (
    <Layout style={{minHeight: '100vh'}}>
      <WebHeader/>
      <Content>
        {props.children}
      </Content>
      <WebFooter/>
      <BackTop/>
    </Layout>
  )
}
const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => {
  return {dispatch}
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
