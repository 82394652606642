// 权限

const LOGIN_CACHE_NAME = 'LoginCache'

export const isAuthenticated = () => {
  return _getCache(LOGIN_CACHE_NAME)
}

export const loginSuccess = (currentUser: any) => {
  _setCache(LOGIN_CACHE_NAME, currentUser)
}

export const logout = () => {
  localStorage.clear()
}


// 菜单
const MENU_CACHE_NAME = 'MenuCache'

export const getMenu = () => {
  return JSON.parse(_getCache(MENU_CACHE_NAME) as string)
}

export const setMenu = (menu: any) => _setCache(MENU_CACHE_NAME, menu)

// 教育等级
const EDU_LEVEL_CACHE_NAME = 'EduLevel'
export const setEduLevel = () => {
  _setCache(EDU_LEVEL_CACHE_NAME, [])
}

export const getEduLevel = () => {
  return JSON.parse(_getCache(EDU_LEVEL_CACHE_NAME) as string)
}

const _getCache = (name: string) => {
  return localStorage.getItem(name)
}

const _setCache = (name: string, value: any) => {
  return localStorage.setItem(name, JSON.stringify(value))
}
