import React from 'react'
import {Layout} from "antd";
import './index.less'
const {Footer: AntdFooter} = Layout


const Footer: React.FC = (props) =>{
  return (
    <AntdFooter style={{ textAlign: 'center' }}></AntdFooter>
  )
}

export default Footer
