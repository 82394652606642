import React from "react";
import {Spin} from 'antd'
import './index.less'


const Loading = () => {
  return (
    <div className="loading">
      <Spin/>
      <span> 加载中，请稍后...</span>
    </div>
  )
}

export default Loading
