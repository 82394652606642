import React from "react";
import {Divider as AntdDivider} from 'antd';
import {DividerProps} from "antd/es/divider";

interface ICustomDividerProps extends DividerProps {

}

const Divider: React.FC<ICustomDividerProps> = (props: ICustomDividerProps) => {
  const {dashed} = props
  return <AntdDivider dashed={dashed}/>
}

export default Divider
