import moment, {Moment} from "moment";


export const momentDatePickerFormatTimeStamp = (m: Moment[]) => {
  return [
    moment(m[0].startOf('day').valueOf()).format("X"),
    moment(m[1].endOf("day").valueOf()).format("X")
  ]
}


export const formatTimeStamp = (startTime:any, endTime:any) => {
  return moment(startTime * 1000).format("YYYY-MM-DD") + " - " + moment(endTime * 1000).format("YYYY-MM-DD")
}
