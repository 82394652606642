import {newPost, post} from "../components/axios";
import {queryActionType} from "./types";

export interface LoginParamsType {
  loginName: string
  loginPwd: string
}

export interface LoginOutputType {
  id: Number
  username: string
  loginName: string
  lastIp: string
  lastTime: Number
  status: Number
  createdAt: Number
  updatedAt: Number
  token: string
  menu: Array<MenuType> | []
}

export interface MenuType {
  id: Number
  pid: Number
  title: string
  icon: string
  url: string
  type: Number
  sort: Number
  status: Number
  createdAt: Number
  updatedAt: Number
}

export const login: queryActionType = (params: LoginParamsType) =>
  post('/v1/user/login', params);


export interface MinUser {
  id: number
  username: string
  loginName: string
}

export const getMinUserList = () => newPost<Array<MinUser>>("v1/user/index-with-min")


export const queryGetUserInfo = (id: number) => newPost<any>("v1/user/info", {id})
