import {fromJS} from 'immutable'
import {actionTypes as types} from './index'
import {IAction} from "store/types";


const defaultState = fromJS({
  userInfo: {},
  saleData: [],
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_USER_INFO:
      return setUserInfo(state, action.payload)
    case types.SET_SALE_DATA:
      return setSaleData(state, action.payload)
    default:
      return state
  }
}


const setUserInfo = (state: any, payload: any) => {
  return state.merge({
    userInfo: fromJS(payload)
  })
}


const setSaleData = (state: any, payload: any) => {
  return state.merge({
    saleData: fromJS(payload)
  })
}
