import {actionTypes as types} from './index'
import {post} from "../../../../components/axios";
import msg, {MessageTypes} from "../../../../components/Message";
import {buildTree} from "../../../../utils/menuTree";

export const handleQueryListData = (params = {}) => {
  return (dispatch: any) => {
    post('/v1/access/index', params).then((data: any) => {
        if (data) {
          const list: [] = data.data
          dispatch(setListData(buildTree(list, 0)))
        }
      }
    )
  }
}


export const handleQueryInfo = (id: number) => {
  return (dispatch: Function) => {
    post('/v1/access/info', {id}).then((data: any) => {
      dispatch(setInfo(data.data))
    })
  }
}

export const handleAddPermission = (data: any) => {
  return (dispatch: any) => {
    post('/v1/access/create', data).then((data: any) => {
      // msg(data.msg, MessageTypes.SUCCESS)
      dispatch(handleQueryListData())
    })
  }
}

export const handleEditPermission = (params: any) => {
  return (dispatch: any) => {
    post('/v1/access/update', params).then((data: any) => {
      dispatch(handleQueryListData())
    })
  }
}

export const handleDeletePermission = (id: number) => {

  return (dispatch: any) => {
    post('/v1/access/delete', {id}).then(() => {
      dispatch(handleQueryListData())
      msg('操作成功', MessageTypes.SUCCESS)
    })
  }
}


export const setInfo = (data: any) => {
  return {
    type: types.SET_INFO,
    payload: data
  }
}


export const setListData = (data: any) => {
  return {
    type: types.SET_LIST_DATA,
    payload: data
  }
}
