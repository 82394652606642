import React, {useMemo} from 'react'
import {Col, Row} from "antd";
import LoginForm from "./components/LoginForm";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom'
import {bindActionCreators} from "redux";
import './index.less'

import {actionCreators} from './store'

const {handleUserLogin} = actionCreators

const Login: React.FC = (props: any) => {
  const {dispatch, isLogin} = props

  const loginFormParams = useMemo(function Login() {
    return bindActionCreators({
      onClickBtnSubmitForm: handleUserLogin,
    }, dispatch)
  }, [])


  return (
    <Row type='flex' className="login-wrapper bg-image">
      <Col span={24} className='login-form-wrapper'>
        <div>
          <h3>帐户登录</h3>
          <p>请输入您的帐户密码</p>
        </div>
        <LoginForm {...loginFormParams}/>
      </Col>
      {isLogin && <Redirect to='/admin/' />}
    </Row>
  );
}


const mapStateToProps = (state: any) => ({
  isLogin: state.getIn(['Login', 'isLogin'])
})

const mapDispatchToProps = (dispatch: any) => {
  return {dispatch}
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
