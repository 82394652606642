import {post} from "../../../../components/axios";
import {actionTypes as types} from './index'

export const handleQueryList = (parmas: any = {}) => {
  return (dispatch: any) => {
    post('/v1/event/index', parmas).then((data: any) => {
      const {data: list, page} = data
      dispatch(setPage(page))
      dispatch(setList(list))
    })
  }
}

export const handleQueryInfo = (id: number) => {
  return (dispatch: Function) => {
    post('/v1/event/info', {id}).then((data: any) => {
      dispatch(setInfo(data['data']))
    })
  }
}

export const handleUpdateEvent = (params: any) => {
  return (dispatch: Function) => {
    post('v1/event/update', params).then(() => {
      dispatch(handleQueryList())
    })
  }
}
export const handleExamine = (params: any) => {
  return (dispatch: Function) => {
    post('v1/event/examine', params).then(() => {
      dispatch(handleQueryList())
    })
  }
}

const setList = (data: any) => {
  return {
    type: types.SET_LIST,
    payload: data
  }
}


const setInfo = (data: any) => {
  return {
    type: types.SET_INFO,
    payload: data
  }
}

const setPage = (page: any) => {
  return {
    type: types.SET_PAGE,
    payload: page
  }
}
