export const SET_USER_LIST_DATA = 'UserArchive/SET_USER_LIST_DATA'

export const SET_INFO = 'UserArchive/SET_INFO'

export const SET_PAGE = 'UserArchive/SET_PAGE'

export const SET_USER_LIST_COLUMNS = 'UserArchive/SET_USER_LIST_COLUMNS'

export const SET_SHOW_TABLE_COLUMNS = 'UserArchive/SET_SHOW_TABLE_COLUMNS'

export const SET_SELECTED_CHECKBOX_OPTIONS = 'UserArchive/SET_SELECTED_CHECKBOX_OPTIONS'

export const SET_CHECKBOX_OPTIONS_COLUMNS = 'UserArchive/SET_CHECKBOX_OPTIONS_COLUMNS'


export const SET_COMPANY_LIST_DATA = 'UserArchive/SET_COMPANY_LIST_DATA'

export const SET_POST_LEVEL_DATA = 'UserArchive/SET_POST_LEVEL_DATA'