import React from 'react'
import {Layout, Menu, Dropdown, Icon} from 'antd';
import './index.less'
import {Link} from "react-router-dom";


const {Header} = Layout;
const logoImg = require('../../../assets/img/logo.png')

const WebFooter = () => {

  const menu = (
    <Menu style={{minWidth: 120, textAlign: 'center'}}>
      <Menu.Item key="1"><Link to={'/'} style={{lineHeight: 2}}><strong>首页</strong></Link></Menu.Item>
      <Menu.Item key="2"><Link to={'/post'} style={{lineHeight: 2}}><strong>岗位详情</strong></Link></Menu.Item>
      <Menu.Item key="3"><Link to={'/about'} style={{lineHeight: 2}}><strong>了解云海螺</strong></Link></Menu.Item>
    </Menu>
  );

  return (
    <Header className={'web-header-wrapper'} style={{backgroundColor: '#ff4343',}}>
      <div className="logo"><img src={logoImg} alt=""/></div>
      <Menu
        className={'web-header-menu'}
        mode="horizontal"
        // defaultSelectedKeys={['2']}
      >
        <Menu.Item key="1"><Link to={'/'}><strong>首页</strong></Link></Menu.Item>
        <Menu.Item key="2"><Link to={'/post'}><strong>岗位详情</strong></Link></Menu.Item>
        <Menu.Item key="3"><Link to={'/about'}><strong>了解云海螺</strong></Link></Menu.Item>
      </Menu>
      <Dropdown className={'web-header-menu-mobile'} overlay={menu} trigger={['click']}>
        <a onClick={e => e.preventDefault()}>
          <Icon type="unordered-list" style={{color: 'white', fontSize: 25}} />
        </a>
      </Dropdown>
    </Header>
  )
}
export default WebFooter