import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios"
import QS from 'qs'
import msg, {MessageTypes} from "../Message"
import store from 'store'
import {handleUserLogout} from "pages/backend/Login/store/actionCreators";
import {isAuthenticated} from "utils/cache";
import {ResponseData} from "api/types";

// const BASE_API_URL = process.env.NODE_ENV === 'production' ? "http://hrapi.ehailuo.com" : "http://hr-api.cn"
const BASE_API_URL = process.env.NODE_ENV === 'production' ? "http://hrapi.ehailuo.com" : "http://hrapi.ehailuo.com"


const instance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 10000,
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.method === 'post') {
      if (!!isAuthenticated()) {
        config.params = {
          'access-token': JSON.parse(isAuthenticated() as string).token
        };
      }
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response: AxiosResponse<ResponseData<any>>) => {
    const {data, status} = response
    if (status !== 200) {
      msg('请求失败', MessageTypes.ERROR)
      // new Error(data.msg)
      return Promise.reject();
    } else {
      if (data.code === 200) {
        // 返回成功
        return data as any
      } else if (data.code === -1) {
        // 请求失败,参数错误
        msg(data.msg, MessageTypes.ERROR)
        return Promise.reject();
      } else if (data.code === 401) {
        // token 过期 清空登陆状态 跳转
        msg('登录状态失效,请重新登录', MessageTypes.ERROR)
        // @ts-ignore
        store.dispatch(handleUserLogout())
        return Promise.reject();
      } else {
        msg(data.msg, MessageTypes.ERROR)
        return Promise.reject();
      }
    }
  },
  (error: AxiosError) => Promise.reject(error)
);


const get = (url: string, params: any = []) => {
  return instance.get(url, params)
}

const post = (url: string, params: any = []) => {
  instance.defaults.baseURL = BASE_API_URL
  return instance.post(url, QS.stringify(params))
}

export function newPost<T>(url: string, params: any = []) {
  instance.defaults.baseURL = BASE_API_URL
  return instance.post<T, ResponseData<T>>(url, QS.stringify(params))
}

const uploadFile = (params: FormData) => {
  instance.defaults.baseURL = 'https://api.ehailuo.com'
  instance.defaults.headers.common['Content-Type'] = 'multipart/form-data'
  const path = '/upload'
  return instance.post(path, params).then((response) => {
      return response
    }
  )
}


export {get, post, uploadFile}
