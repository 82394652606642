import React from "react";
import {Avatar, Dropdown, Icon, Menu} from "antd";
import {Link} from "react-router-dom";
import './index.less'


const HeaderDropdown = (props: any) => {
  const {currentUser, handleUserLogout} = props

  const DownMenu = () => {
    return (
      <Menu>
        {/*<Menu.Item key="0">*/}
        {/*  /!*<Link to={'/admin/user-center'}>*!/*/}
        {/*  <Link to={'/admin/user-center'}>*/}
        {/*    <Icon type="user"/>*/}
        {/*    <span>个人中心</span>*/}
        {/*  </Link>*/}
        {/*</Menu.Item>*/}
        <Menu.Item key="1">
          <Link to={'/admin/user-settings'}>
            <Icon type="setting"/>
            <span> 个人设置</span>
          </Link>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="3" onClick={() => handleUserLogout()}>
          <Icon type="logout"/>
          <span>退出登陆</span>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Dropdown overlay={DownMenu}>
      <span className="ant-dropdown-link">
        <Avatar icon="user" style={{marginRight: 8}}/>
        {currentUser.get('username')} <Icon type="down"/>
      </span>
    </Dropdown>
  )
}
export default HeaderDropdown
