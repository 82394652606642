import React from "react";
import {Icon, Menu} from "antd";
import {Link} from 'react-router-dom'


const {SubMenu} = Menu;

const NavItem = (item: any) => {
  return (
    <Menu.Item key={item.id}>
      <Link to={item.url}>
        <Icon type={item.icon}/>
        <span>{item.title}</span>
      </Link>
    </Menu.Item>
  )
}

const RenderMenu = (MenuConfig: any) => {
  return MenuConfig.map((item: any) => {
    if (item.children && item.children.length) {
      return (
        <SubMenu
          key={item.id}
          title={
            <span>
              <Icon type={item.icon}/>
              <span>{item.title}</span>
            </span>
          }
        >
          {
            RenderMenu(item.children)
          }
        </SubMenu>
      )
    }
    return NavItem(item)
  })
}

export default RenderMenu
