import {actionTypes as types} from './index'
import {post} from "../../../../components/axios";

export const handleQueryList = (params: any = {}) => {
  return (dispatch: Function) => {
    post('/v1/job-classification/index', params).then((data: any) => {
      const {data: list, page} = data
      dispatch(setPage(page))
      dispatch(setList(list))
    })
  }
}

export const handleQueryInfo = (id: number | string) => {
  return (dispatch: Function) => {
    post('/v1/job-classification/info', {id}).then((data: any) => {
      dispatch(setInfo(data['data']))
    })
  }
}


export const handleAddData = (params: any) => {
  return (dispatch: Function) => {
    post('/v1/job-classification/create', params).then(() => {
      dispatch(handleQueryList([]))
    })
  }
}

export const handleEditData = (params: any) => {
  return (dispatch: Function) => {
    post('/v1/job-classification/edit', params).then(() => {
      dispatch(handleQueryList([]))
    })
  }
}


export const handleDel = (id: number | string) => {
  return (dispatch: Function) => {
    post('v1/job-classification/del', {id}).then(() => {
      dispatch(handleQueryList())
    })
  }
}


 const setList = (params: any) => {
  return {
    type: types.SET_LIST,
    payload: params
  }
}

export const setInfo = (params: any) => {
  return {
    type: types.SET_INFO,
    payload: params
  }
}
const setPage = (page: any) => {
  return {
    type: types.SET_PAGE,
    payload: page
  }
}
