import {queryCreate, queryDel, queryList} from "../../../../api/SalesAssessmentPeriodConfiguration";
import {actionTypes as types} from "../store";


export const handleCreate = (params: any) => {
  return async (dispatch: any) => {
    const res = await queryCreate(params)
    if (res) {
      dispatch(handleGetList())
      return true
    }
  }
}


export const handleDel = (periodId: number) => {
  return async (dispatch: any) => {
    await queryDel(periodId)
    dispatch(handleGetList())
  }
}

export const handleGetList = () => {
  return async (dispatch: any) => {
    const res = await queryList()
    dispatch(setList(res.data || []))
    dispatch(setPage(res.page))
  }
}


export const setList = (params: any) => {
  return {
    type: types.SET_LIST,
    payload: params
  }
}

const setPage = (page: any) => {
  return {
    type: types.SET_PAGE,
    payload: page
  }
}

