import React from 'react'
import {Layout, Icon} from "antd";
import './index.less'
import HeaderDropdown from "../HeaderDropdown";
import {LayoutProps} from "antd/es/layout";

const {Header: AntdHeader} = Layout;

interface ICustomHeaderProps extends LayoutProps {
  collapsed?: boolean
  handleToggle?: any
  currentUser?: any
  handleUserLogout?: Function
}

const Header: React.FC<ICustomHeaderProps> = (props: ICustomHeaderProps) => {
  const {collapsed, handleToggle, currentUser, handleUserLogout} = props

  return (
    <AntdHeader style={{background: '#fff', padding: 0}}>
      <Icon
        className="trigger"
        type={collapsed ? 'menu-unfold' : 'menu-fold'}
        onClick={() => handleToggle(!collapsed)}
      />
      <span className='header-right-content'>
        <HeaderDropdown currentUser={currentUser} handleUserLogout={handleUserLogout}/>
      </span>
    </AntdHeader>
  )
}

export default Header
