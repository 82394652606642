import {fromJS} from 'immutable'
import {actionTypes as types} from './index'

interface IAction {
  type: String,
  payload: any
}

const defaultState = fromJS({
  availableList: fromJS([]),
  jobList: fromJS([]),
  jobInfo: fromJS([]),
  eduList: fromJS([]),
  commonEnglishLevel: fromJS([]),
  customEnglishLevel: fromJS([]),
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_AVAILABLE_LIST:
      return setAvailableList(state, action.payload)
    case types.SET_JOB_LIST:
      return setJobList(state, action.payload)
    case types.SET_JOB_INFO:
      return setJobInfo(state, action.payload)
    case types.SET_EDU_LIST:
      return setEduList(state, action.payload)
    case types.SET_COMMON_ENGLISH_LEVEL:
      return setCommonEnglishLevel(state, action.payload)
    case types.SET_CUSTOM_ENGLISH_LEVEL:
      return setCustomEnglishLevel(state, action.payload)
    default:
      return state
  }
}

const setAvailableList = (state: any, payload: any) => {
  return state.merge({
    availableList: fromJS(payload)
  })
}
const setJobList = (state: any, payload: any) => {
  return state.merge({
    jobList: fromJS(payload)
  })
}

const setJobInfo = (state: any, payload: any) => {
  return state.merge({
    jobInfo: fromJS(payload)
  })
}
const setEduList = (state: any, payload: any) => {
  return state.merge({
    eduList: fromJS(payload)
  })
}

const setCommonEnglishLevel = (state: any, payload: any) => {
  return state.merge({
    commonEnglishLevel: fromJS(payload)
  })
}
const setCustomEnglishLevel = (state: any, payload: any) => {
  return state.merge({
    customEnglishLevel: fromJS(payload)
  })
}