import {post} from "../components/axios";
import {Md5} from "ts-md5";


export const queryBindUserList = () =>  post("/v1/school/info")

export const queryBindingUser = (params: any) => post("/v1/school/bind", {...params, password: Md5.hashStr(params.password)})

export const queryUnBundlingUser = (agentUid: number) => post("/v1/school/unbind", {agentUid})


export const queryBindUserPhone = (params: any) => post("v1/school/bind-tel", params)
