import React from "react";
import {Button, Form, Icon, Input} from 'antd';
import {FormProps} from "antd/es/form";
import './index.less'

interface ILoginFormProps extends FormProps {
  onClickBtnSubmitForm: Function
}

const LoginForm: React.FC<ILoginFormProps> = (props: ILoginFormProps) => {
  const form = props.form;
  // @ts-ignore
  const {form: {getFieldDecorator}, onClickBtnSubmitForm} = props;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form && form.validateFields((err: any, values: any) => {
      if (!err) {
        onClickBtnSubmitForm(values)
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item>
        {getFieldDecorator('loginName', {
          rules: [{required: true, message: 'Please input your username!'}],
        })(
          <Input
            size={"large"}
            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
            placeholder="Username"
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('loginPwd', {
          rules: [{required: true, message: 'Please input your Password!'}],
        })(
          <Input
            size={"large"}
            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
            type="password"
            placeholder="Password"
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Button
          size={"large"}
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          登陆
        </Button>
      </Form.Item>
    </Form>
  )
}

const WrappedLoginForm: any = Form.create({name: 'LoginForm'})(LoginForm)

export default WrappedLoginForm
