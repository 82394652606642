import {queryBindUserPhone} from "../../../../api/Binding";
import {getUserInfo} from "../../Home/store/actionCreators";
import {isAuthenticated} from "../../../../utils/cache";

export const handleBindingPhone = (params: any) => {
  return async (dispatch: any, getState: any) => {
    const res = await queryBindUserPhone(params)
    const uid = JSON.parse(isAuthenticated() as string).id
    res && dispatch(getUserInfo(uid))
  }
}
