import React from "react";
import {Breadcrumb, Typography} from "antd";
import {useLocation} from 'react-router-dom'
import {getMenu} from 'utils/cache'
import {useTitle} from "ahooks";

const {Paragraph: AntdParagraph} = Typography;

let RenderBreadcrumb = (array: any, path: any) => {
  let stack: any = [];
  let going = true;
  let walker = (array: any, path: any) => {
    array && array.forEach((item: any) => {
      if (!going) return;
      stack.push({
        id: item['id'],
        title: item['title'],
      });
      if (item['url'] === path) {
        going = false;
      } else if (item['children']) {
        walker(item['children'], path);
      } else {
        stack.pop();
      }
    });
    if (going) stack.pop();
  }
  walker(array, path);
  return stack;
}


const PageHeader = () => {
  const pathArr = RenderBreadcrumb(getMenu(), useLocation().pathname)
  useTitle('欢迎来到云海螺^-^');
  return (
    <AntdParagraph>
      <Breadcrumb>
        {
          pathArr && pathArr.map((item: any) => {
            return <Breadcrumb.Item key={item['id']}>{item['title']}</Breadcrumb.Item>
          })
        }
      </Breadcrumb>
    </AntdParagraph>
  )
}
export default PageHeader
