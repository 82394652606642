import thunk from 'redux-thunk'
import reducer from './reducer'
import {compose, createStore, applyMiddleware} from "redux";

const composeEnhancers = ((window as any) && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const store = createStore(reducer, composeEnhancers(
  applyMiddleware(thunk)
))



export default store
