import {actionTypes as types} from './index'
import {queryBindingUser, queryBindUserList, queryUnBundlingUser} from "../../../../../../api/Binding";


export const handleBindingList = () => {
  return async (dispatch: any, getState: any) => {
    const res = await queryBindUserList()
    res && dispatch(setBindingList(res.data))
  }
}


export const handleUnbinding = (agentUid: any) => {
  return async (dispatch: any, getState: any) => {
    const res = await queryUnBundlingUser(agentUid)
    res && dispatch(handleBindingList())
  }
}

export const handleBinding = (params: any) => {
  return async (dispatch: any, getState: any) => {
    const res = await queryBindingUser(params)
    res && dispatch(handleBindingList())
  }
}

const setBindingList = (data: any) => {
  return {
    type: types.SET_BINDING_LIST,
    payload: data
  }
}
