import {fromJS} from 'immutable'
import {actionTypes as types} from './index'
import {IAction} from "store/types";

const defaultState = fromJS({
  list: fromJS([]),
  info: fromJS([]),
  pagination: fromJS({
    current: 1,
    pageSize: 15,
    total: 0
  })
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_LIST_DATA:
      return setListData(state, action.payload)
    case types.SET_INFO:
      return setInfo(state, action.payload)
    default:
      return state
  }
}
const setListData = (state: any, payload: any) => {
  return state.merge({
    list: fromJS(payload)
  })
}
const setInfo = (state: any, payload: any) => {
  return state.merge({
    info: fromJS(payload)
  })
}
