import {fromJS} from 'immutable'
import {actionTypes as types} from './index'
import {IAction} from "store/types";


const defaultState = fromJS({

})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    default:
      return state
  }
}

