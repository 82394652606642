import {fromJS, List} from 'immutable'
import {actionTypes as types} from './index'
import {IAction} from "store/types";
import {Event} from "api/Event";
import {Page} from "api/types";

export interface ApplyDefaultState {
  list: List<Array<Event> | []>
  info: List<Event | []>
  page: List<Page>
}

const defaultState: ApplyDefaultState = fromJS({
  list: fromJS([]),
  info: fromJS([]),
  page: fromJS([]),
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_LIST:
      return setList(state, action.payload)
    case types.SET_INFO:
      return setInfo(state, action.payload)
    case types.SET_PAGE:
      return setPage(state, action.payload)
    default:
      return state
  }
}

const setList = (state: any, payload: Array<Event>) => {
  return state.merge({
    list: fromJS(payload)
  })
}

const setInfo = (state: any, payload: Event) => {
  return state.merge({
    info: fromJS(payload)
  })
}

const setPage = (state: any, payload: Page) => {
  return state.merge({
    page: fromJS(payload)
  })
}
