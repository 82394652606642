import {queryGetUserInfo} from "../../../../api/User";
import {actionTypes as types} from "./index";
import {queryGetInfo} from "../../../../api/Sale";


export const getUserInfo = (id: any) => {
  return async (dispatch: any) => {
    const res = await queryGetUserInfo(id)
    if (res) {
      dispatch(setInfo(res.data))
    }
  }
}

export const getSaleData = (uid: any) => {
  return async (dispatch: any) => {
    const res = await queryGetInfo({uid})
    dispatch(setSaleData(res.data || []))
  }
}

const setInfo = (data: any) => {
  return {
    type: types.SET_USER_INFO,
    payload: data
  }
}
const setSaleData = (data: any) => {
  return {
    type: types.SET_SALE_DATA,
    payload: data
  }
}
