import {fromJS} from 'immutable'
import {IAction} from "store/types";


const defaultState = fromJS({})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case '1':
    default:
      return state
  }
}
