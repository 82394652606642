import React, {Component, FC, useEffect, useMemo} from "react";
import {connect, useDispatch} from "react-redux";
import {Redirect, Route, useHistory, useLocation} from 'react-router-dom'
import {handleCacheData} from "../pages/backend/Login/store/actionCreators";
import {bindActionCreators} from "redux";
import {getMenu, isAuthenticated} from "../utils/cache";


const IsLogin = () => {
  return !!isAuthenticated()
}

const allowPath = [
  "/admin/403",
  "/admin/500",
  "/admin/404",
  "/admin/user-settings",
]

const checkMenu = (path: string, m: any): boolean => {
  if (allowPath.indexOf(path) > -1) {
    return true
  }
  let menuLen = m.length
  for (let i = 0; i < menuLen; i++) {
    if (m[i].url == path) {
      return true
    }
  }
  return false
}

const PrivateRoute: FC<any> = ({component: Component, ...rest}) => {
  // const l = useLocation()
  // const h = useHistory()
  // const m = getMenu()
  // if (m && isAuthenticated()) {
  //   const res = checkMenu(l.pathname, m)
  //   !res && h.push("/admin/403")
  // }


  const dispatch = useDispatch()

  // 处理缓存登录信息
  const cbs = useMemo(function tableHeaderCbs() {
    return bindActionCreators({
      handleCacheData
    }, dispatch)
  }, [])

  useEffect(function () {
    cbs.handleCacheData()
  })

  return (
    <Route
      {...rest}
      render={props =>
        IsLogin() ? <Component {...props} />
          : <Redirect
            to={{
              pathname: "/admin/login",
              state: {from: props.location}
            }}
          />
      }
    />
  );
}
const mapStateToProps = (state: any) => ({
  isLogin: state.getIn(['Login', 'isLogin'])
})

export default connect(mapStateToProps)(PrivateRoute)
