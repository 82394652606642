import {fromJS} from 'immutable'
import {actionTypes as types} from './index'
import {IAction} from "store/types";


const defaultState = fromJS({
  currentUser: fromJS([]),
  isLogin: false,
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_CURRENT_USER_INFO:
      return setCurrentUserInfo(state, action.payload)
    case types.CHANGE_LOGIN_STATUS:
      return changeLoginStatus(state, action.payload)
    default:
      return state
  }
}

const setCurrentUserInfo = (state: any, payload: any) => {
  return state.merge({
    currentUser: fromJS(payload)
  })
}

const changeLoginStatus = (state: any, payload: any) => {
  return state.merge({
    isLogin: payload
  })
}
