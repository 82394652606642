import {fromJS} from 'immutable'
import {actionTypes as types} from './index'
import {IAction} from "store/types";

const defaultState = fromJS({
  list: fromJS([]),
  validList: fromJS([]),
  info: fromJS([]),
  page: fromJS([]),
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_VALID_LIST:
      return setValidList(state, action.payload)
    case types.SET_LIST:
      return setList(state, action.payload)
    case types.SET_INFO:
      return setInfo(state, action.payload)
    case types.SET_PAGE:
      return setPage(state, action.payload)
    default:
      return state
  }
}

const setValidList = (state: any, payload: any) => {
  return state.merge({
    validList: fromJS(payload)
  })
}
const setList = (state: any, payload: any) => {
  return state.merge({
    list: fromJS(payload)
  })
}

const setInfo = (state: any, payload: any) => {
  return state.merge({
    info: fromJS(payload)
  })
}

const setPage = (state: any, payload: any) => {
  return state.merge({
    page: fromJS(payload)
  })
}
