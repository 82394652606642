import {newPost} from "components/axios";
import {queryActionType} from "./types";

export interface Event {
  id: number
  uid: number
  uName: string
  name: string
  opsId: number
  opsName: string
  opsTime: number
  remark: string | null
  status: number
  createdAt: number
  updatedAt: number
  content?: EventContent
}

export interface EventContent {
  [name: string]: any
}

export interface ListParamsType {
  status?: number
}

export const list = (params: ListParamsType | [] = []) =>
  newPost<Array<Event>>("v1/event/current-index", params)

export interface InfoParamsType {
  id: number
}

export const info: queryActionType = (params: InfoParamsType) =>
  newPost<Event>("v1/event/info", params)

interface UpdateParamsType {
  id: number
  title: string
}

export const update: queryActionType = (params: UpdateParamsType) =>
  newPost<void>('v1/event/update', params)
