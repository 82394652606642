import React from 'react'
import {Layout, Menu} from 'antd';
import './index.less'
import RenderMenu from "./RenderMenu";
import {LayoutProps} from "antd/es/layout";
import {IMenu} from "../../../conifg/menu";


interface ICustomSideProps extends LayoutProps {
  collapsed: boolean
  MenuConfig: Array<IMenu>
}

const NavLeft: React.FC<ICustomSideProps> = (props: ICustomSideProps) => {
  const {collapsed, MenuConfig} = props
  return (
    <Layout.Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo"/>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['/home']}
      >
        {
          MenuConfig && RenderMenu(MenuConfig)
        }
      </Menu>
    </Layout.Sider>
  )
}

export default NavLeft
