import {fromJS} from 'immutable'
import {actionTypes as types} from './index'
import React from "react";
import {IAction} from "store/types";


const defaultState = fromJS({
  isLoadingUserList: false,
  listData: fromJS([]),
  tableColumns: fromJS([]),
  showTableColumns: fromJS([]),
  checkboxOptions: fromJS([]),
  defaultSelectedCheckBoxOptions: fromJS([]),
  info: fromJS([]),
  page: fromJS([]),
  companyInfo: fromJS([]),
  postLevel: fromJS([]),
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_USER_LIST_DATA:
      return setUserListData(state, action.payload)

    case types.SET_USER_LIST_COLUMNS:
      return setUserListColumns(state, action.payload)

    case types.SET_SHOW_TABLE_COLUMNS:
      return setShowTableColumns(state, action.payload)

    case types.SET_SELECTED_CHECKBOX_OPTIONS:
      return setSelectedCheckboxOptions(state, action.payload)

    case types.SET_CHECKBOX_OPTIONS_COLUMNS:
      return setCheckboxOptions(state, action.payload)

    case types.SET_INFO:
      return setInfo(state, action.payload)
    case types.SET_PAGE:
      return setPage(state, action.payload)

    case types.SET_COMPANY_LIST_DATA:
      return setCompanyInfo(state, action.payload)
    case  types.SET_POST_LEVEL_DATA:
      return setPostLevel(state, action.payload)
    default:
      return state
  }
}
const setPostLevel = (state: any, payload: any) => {
  return state.merge({
    postLevel: fromJS(payload)
  })
}


const setCompanyInfo = (state: any, payload: any) => {
  return state.merge({
    companyInfo: fromJS(payload)
  })
}

const setUserListData = (state: any, payload: any) => {
  return state.merge({
    listData: fromJS(payload)
  })
}

const setUserListColumns = (state: any, payload: any) => {
  return state.merge({
    tableColumns: fromJS(payload)
  })
}


const setShowTableColumns = (state: any, payload: any) => {
  return state.merge({
    showTableColumns: fromJS(payload)
  })
}

const setCheckboxOptions = (state: any, payload: any) => {
  return state.merge({
    checkboxOptions: fromJS(payload)
  })
}

const setSelectedCheckboxOptions = (state: any, payload: any) => {
  return state.merge({
    defaultSelectedCheckBoxOptions: fromJS(payload)
  })
}

const setInfo = (state: any, payload: any) => {
  return state.merge({
    info: fromJS(payload)
  })
}
const setPage = (state: any, payload: any) => {
  return state.merge({
    page: fromJS(payload)
  })
}
