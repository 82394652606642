import {message} from "antd";
import {MessageType} from "antd/es/message"

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
});

export enum MessageTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  WARN = 'warn',
  LOADING = 'loading',
}

const msg = (content: string, type: MessageTypes = MessageTypes.INFO): MessageType => {
  return message[type](content)
}

export default msg
