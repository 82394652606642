import {actionTypes as types} from './index'
import {create, del, update, info, list} from "api/Company";

export const handleQueryList = (params: any = {}) => {
  return (dispatch: Function) => {
    list(params).then((result: any) => {
      const {data} = result
      dispatch(setList(data))
    })
  }
}

export const handleQueryInfo = (id: number) => {
  return (dispatch: Function) => {
    info({id}).then(result => {
      const {data} = result
      dispatch(setInfo(data))
    })
  }
}

export const handleCreate = (params: any) => {
  return (dispatch: Function) => {
    create(params).then(result => {
      dispatch(handleQueryList())
    })
  }
}
export const handleUpdate = (params: any) => {
  return (dispatch: Function) => {
    update(params).then(result => {
      dispatch(handleQueryList())
    })
  }
}

export const handleDel = (id: number) => {
  return (dispatch: Function) => {
    del({id}).then(result => {
      dispatch(handleQueryList())
    })
  }
}

const setList = (data: any) => {
  return {
    type: types.SET_LIST,
    payload: data
  }
}

export const setInfo = (data: any) => {
  return {
    type: types.SET_INFO,
    payload: data
  }
}
