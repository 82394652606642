import React from 'react'
import {Col, Row} from 'antd';
import './index.less'
import ImgUrl from 'assets/img/1.jpg'
import mobileImgUrl from 'assets/img/mobile.jpg'

const WebHome = (props: any) => {

  const IsPC = () => {
    let userAgentInfo = navigator.userAgent
    let Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  return <Row>
    <Col span={24} className={'web-home-wrapper'}>
      <img style={{width: '100%'}} src={IsPC() ? ImgUrl : mobileImgUrl}/>
    </Col>
  </Row>
}

export default WebHome