import {fromJS} from 'immutable'
import {IAction} from "store/types";
import {actionTypes as types} from "./";


const defaultState = fromJS({
  columns: fromJS([]),
  saleList: fromJS([]),
  selectParams: fromJS([]),
  saleHistoryList: fromJS([]),
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_COLUMNS_LIST:
      return setColumnsData(state, action.payload)
    case types.SET_PAGE:
      return setPage(state, action.payload)
    case types.SET_SALE_LIST:
      return setSaleList(state, action.payload)
    case types.SET_PARAMS:
      return setParams(state, action.payload)
    case types.SET_SALE_HISTORY_LIST:
      return setSaleHistoryList(state, action.payload)
    default:
      return state
  }
}

const setSaleList = (state: any, payload: any) => {
  return state.merge({
    saleList: fromJS(payload)
  })
}
const setSaleHistoryList = (state: any, payload: any) => {
  return state.merge({
    saleHistoryList: fromJS(payload)
  })
}

const setColumnsData = (state: any, payload: any) => {
  return state.merge({
    columns: fromJS(payload)
  })
}

const setPage = (state: any, payload: any) => {
  return state.merge({
    page: fromJS(payload)
  })
}


const setParams = (state: any, payload: any) => {
  return state.merge({
    selectParams: payload
  })
}
