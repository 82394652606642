export interface MenuTreeInterface {
  id: Number,
  pid: Number,
  title: String,
  icon: String,
  url: String,
  type: Number,
  sort: Number,
  status: Number,
  createdAt: Number,
  updatedAt: Number,
  children?: Array<MenuTreeInterface>
}

export const buildTree = (menu: Array<MenuTreeInterface>, pid = 0): Array<MenuTreeInterface> | [] => {
  let arr: Array<MenuTreeInterface> = [];
  menu.map((item, index) => {
    if (item.pid === pid) {
      let dom = item
      delete (menu[index])
      dom.children = buildTree(menu, Number(item.id))
      arr.push(dom)
    }
  })
  return arr
}
