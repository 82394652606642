import {actionTypes as types} from './index'
import {Event, info, list, update} from "api/Event";
import {Dispatch} from "redux";
import {Page} from "api/types";


export const handleQueryList = (params: any = {}) => {
  return async (dispatch: Dispatch) => {
    const {data, page} = await list(params)
    dispatch(setPage(page))
    dispatch(setList(data))
  }
}

export const handleQueryInfo = (id: number) => {
  return async (dispatch: Dispatch) => {
    const {data} = await info({id})
    dispatch(setInfo(data))
  }
}


export const handleUpdateEvent = (params: any) => {
  return async (dispatch: Dispatch<any>) => {
    await update(params)
    dispatch(handleQueryList())
  }
}

const setList = (data: Array<Event>) => {
  return {
    type: types.SET_LIST,
    payload: data
  }
}


const setInfo = (data: Event) => {
  return {
    type: types.SET_INFO,
    payload: data
  }
}

const setPage = (page: Page) => {
  return {
    type: types.SET_PAGE,
    payload: page
  }
}
