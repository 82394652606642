import {post} from "../../../../components/axios";
import {actionTypes as types} from "./index";
import msg, {MessageTypes} from "../../../../components/Message";

export const handleQueryListData = (params: any = {}) => {
  return (dispatch: any) => {
    post('/v1/role/index', params).then((result: any) => {
      const {data, page} = result
      dispatch(setListData(data))
      dispatch(setPage(page))
    })
  }
}

export const handleQueryInfo = (id: number) => {
  return (dispatch: Function) => {
    post('/v1/role/info', {id}).then((data: any) => {
      dispatch(setInfo(data.data))
    })
  }
}

export const handleAddRole = (data: any) => {
  return (dispatch: any) => {
    post('/v1/role/create', data).then((data: any) => {
      dispatch(handleQueryListData())
    })
  }
}

export const handleEditRole = (data: any) => {
  return (dispatch: any) => {
    post('/v1/role/update', data).then(() => {
      dispatch(handleQueryListData())
    })
  }
}

export const handleDeleteRole = (id: number) => {
  return (dispatch: any) => {
    post('/v1/role/delete', {id}).then(() => {
      dispatch(handleQueryListData())
      msg('操作成功', MessageTypes.SUCCESS)
    })
  }
}

export const setInfo = (data: any) => {
  return {
    type: types.SET_INFO,
    payload: data
  }
}

export const setListData = (data: any) => {
  return {
    type: types.SET_LIST_DATA,
    payload: data
  }
}

const setPage = (page: any) => {
  return {
    type: types.SET_PAGE,
    payload: page
  }
}
