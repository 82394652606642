import {actionTypes as types} from './index'
import React from 'react'
import {fromJS} from "immutable";
import {post} from "../../../../components/axios";
import moment from 'moment'
import {postLevel} from "../../../../api/Level";
import msg from "../../../../components/Message";


export const queryTableListData = (params: any = {}) => {
  return (dispatch: any, getState: any) => {

    post('v1/user-archive/index', params).then((result: any) => {
      const {data, page} = result
      let list = data.map((item: any) => {
        return formatData(item)
      })
      dispatch(setPage(page))
      dispatch(setTableList(list))
    })
  }
}

const formatData = (item: any) => {
  // 工作经验
  let dateInterval = item.work.map((item: any) => {
    return [
      moment.unix(item.start_time).format('YYYY-MM-DD'),
      moment.unix(item.end_time).format('YYYY-MM-DD')
    ]
  })

  let companyName = item.work.map((item: any) => {
    return item.company_name
  })
  let department = item.work.map((item: any) => {
    return item.department
  })
  let job = item.work.map((item: any) => {
    return item.job
  })

  // 家庭成员
  let familyUsername = item.family.map((item: any) => {
    return item.username
  })
  let familyAge = item.family.map((item: any) => {
    return item.age
  })
  let familyRelationship = item.family.map((item: any) => {
    return item.relationship
  })
  let familyCompany = item.family.map((item: any) => {
    return item.company
  })
  let familyMobile = item.family.map((item: any) => {
    return item.mobile
  })

  return {
    id: item.id,
    username: item.username,
    gender: item.gender,
    nation: item.nation,
    politicsFace: item.politicsFace,
    birthday: moment.unix(item.birthday).format('YYYY-MM-DD'),
    identity: item.identity,
    mobile: item.mobile,
    avatar: item.avatar,
    companyEmail: item.companyEmail,
    qq: item.qq,
    email: item.email,
    marital: item.marital,
    pregnancy: item.pregnancy,
    studyAbroad: item.studyAbroad,
    graduatedSchool: item.graduatedSchool,
    graduatedDate: moment.unix(item.graduatedDate).format('YYYY-MM-DD'),
    major: item.major,
    education: item.education,
    emergencyContact: item.emergencyContact,
    emergencyContactMobile: item.emergencyContactMobile,
    permanentAddress: item.permanentAddress,
    familyPhone: item.familyPhone,
    currentAddress: item.currentAddress,
    registeredResidence: item.registeredResidence,
    remark: item.remark,
    // 工作经验
    dateInterval,
    companyName,
    department,
    job,
    // 家庭成员
    familyUsername,
    familyAge,
    familyRelationship,
    familyCompany,
    familyMobile,
    // 公司信息
    company: item.company
  }
}


export const handleQueryInfo = (id: any) => {
  return (dispatch: Function) => {
    post('v1/user-archive/info', {id}).then((data: any) => {
      data = formatData(data['data'])
      dispatch(setInfo(data))
    })
  }
}

export const changeCheckBoxOptions = (data: any) => {
  return (dispatch: any, getState: any) => {
    const tableColumns = getState().getIn(['UserList', 'tableColumns'])

    const newShowTableColumns: Array<{}> = []
    tableColumns.map((item: any) => {
      if (fromJS(data).includes(item.get('title'))) {
        newShowTableColumns.push(item.toJS())
      }
    })

    dispatch(setShowTableColumns(newShowTableColumns))

    dispatch(setSelectedCheckboxOptions(data))

    localStorageData(cacheKey, data)
  }
}

export const searchListData = (data: any) => {
  return (dispatch: any) => {
    dispatch(queryTableListData(data))
    // post('/search', data).then(data => {
    //   dispatch(setTableList(data))
    // })
  }
}

const buildDefaultSelectedShowTableColumns = (columns: any) => {
  return (dispatch: any, getState: any) => {
    const allColumns: Array<string> = []
    const allTableColumns = columns.map((column: any) => {
      allColumns.push(column.title)
      return {
        label: column.title,
        value: column.title,
      }
    })

    const cacheData = getCacheSelectedCheckboxOptions()

    if (cacheData) {
      // 功能重复 需要重新封装 changeCheckBoxOptions
      const newShowTableColumns: Array<{}> = []
      fromJS(columns).map((item: any) => {
        if (fromJS(cacheData).includes(item.get('title'))) {
          newShowTableColumns.push(item.toJS())
        }
      })

      columns = newShowTableColumns
      dispatch(setSelectedCheckboxOptions(cacheData))
    } else {
      dispatch(setSelectedCheckboxOptions(allColumns))
    }

    dispatch(setCheckboxOptions(allTableColumns))

    dispatch(setShowTableColumns(columns))
  }
}


export const handleAddUser = (data: any) => {
  return (dispatch: any) => {
    // 请求接口
    dispatch(queryTableListData())
  }
}

//  公司信息
export const handleUpdateCompany = (params: any) => {
  return (dispatch: any) => {
    post('v1/user-archive/update-company', params).then(result => {
      msg('操作成功')
    })
  }
}

export const handleQueryCompanyInfo = (id: any) => {
  return (dispatch: Function) => {
    post('v1/user-archive/company-info', {archivesId: id}).then((result: any) => {
      const {data} = result
      if (data) {
        data['dateInterval'] = [
          moment(data.startTime * 1000),
          moment(data.endTime * 1000)
        ]
      }
      dispatch(setCompanyInfo(data))
    })
  }
}

// 岗位等级
export const handleQueryLevelPost = () => {
  return (dispatch: Function) => {
    postLevel().then(result => {
      const {data} = result
      dispatch(setPostLevel(data))
    })
  }
}


const setPostLevel = (data: any) => {
  return {
    type: types.SET_POST_LEVEL_DATA,
    payload: data
  }
}

const setCompanyInfo = (data: any) => {
  return {
    type: types.SET_COMPANY_LIST_DATA,
    payload: data
  }
}

const setTableList = (data: any) => {
  return {
    type: types.SET_USER_LIST_DATA,
    payload: data
  }
}

const setTableColumns = (data: any) => {
  return {
    type: types.SET_USER_LIST_COLUMNS,
    payload: data
  }
}

const setSelectedCheckboxOptions = (data: any) => {
  return {
    type: types.SET_SELECTED_CHECKBOX_OPTIONS,
    payload: data
  }
}

const setShowTableColumns = (data: any) => {
  return {
    type: types.SET_SHOW_TABLE_COLUMNS,
    payload: data
  }
}

const setCheckboxOptions = (data: any) => {
  return {
    type: types.SET_CHECKBOX_OPTIONS_COLUMNS,
    payload: data
  }
}

const setInfo = (data: any) => {
  return {
    type: types.SET_INFO,
    payload: data
  }
}


const cacheKey = 'user-list-selected-checkbox-options'
const getCacheSelectedCheckboxOptions = () => {
  const cache = JSON.parse(localStorage.getItem(cacheKey) as string) || {}
  if (cache && Date.now() < cache.expires) {
    return fromJS(cache.data)
  }
  return false
}

const localStorageData = (key: string, data: any): void => {
  localStorage.setItem(
    key,
    JSON.stringify({
      expires: Date.now() + 60 * 1000,
      data,
    }))
}
const setPage = (page: any) => {
  return {
    type: types.SET_PAGE,
    payload: page
  }
}
