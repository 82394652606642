import {fromJS} from 'immutable'
import {actionTypes as types} from './index'
import React from "react";
import {IAction} from "store/types";

const defaultState = fromJS({
  list: fromJS([]),
  info: fromJS([]),
  page: fromJS([]),
})

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.SET_USER_LIST_DATA:
      return setUserListData(state, action.payload)
    case types.SET_USER_INFO:
      return setUserInfo(state, action.payload)
    case types.SET_PAGE:
      return setPage(state, action.payload)
    default:
      return state
  }
}

const setUserListData = (state: any, payload: any) => {
  return state.merge({
    list: fromJS(payload)
  })
}

const setUserInfo = (state: any, payload: any) => {
  return state.merge({
    info: fromJS(payload)
  })
}
const setPage = (state: any, payload: any) => {
  return state.merge({
    page: fromJS(payload)
  })
}
