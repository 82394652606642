import {post} from "../components/axios";
import {queryActionType} from "./types";

interface ListParamsType {
  status?: number
}

export const list: queryActionType = (params: ListParamsType | [] = []) =>
  post('v1/company/index', params)


interface InfoParamsType {
  id: number
}

export const info: queryActionType = (params: InfoParamsType) =>
  post('v1/company/info', params)

interface CreateParamsType {
  title: string
}

export const create: queryActionType = (params: CreateParamsType) =>
  post('v1/company/create', params)

interface UpdateParamsType {
  id: number
  title: string
}

export const update: queryActionType = (params: UpdateParamsType) =>
  post('v1/company/update', params)

interface DelParamsType {
  id: number
}

export const del: queryActionType = (params: DelParamsType) =>
  post('v1/company/del', params)
