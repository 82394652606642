import {actionTypes as types} from './index'
import msg, {MessageTypes} from "components/Message";

import {isAuthenticated, loginSuccess, logout, setMenu} from 'utils/cache'
import {buildTree} from "utils/menuTree";
import {login, LoginParamsType} from "api/User";

export const handleUserLogin = (params: LoginParamsType) => {
  return (dispatch: any) => {
    login(params).then((data: any) => {
      const currentUser = data.data
      // 加入缓存
      const {menu, ...user} = currentUser
      loginSuccess(user)
      setMenu(buildTree(menu))

      dispatch(setCurrentUser(currentUser))
      dispatch(changeLoginStatus(true))
      msg('登录成功', MessageTypes.SUCCESS)
    })
  }
}


export const handleUserLogout = () => {
  return (dispatch: any) => {
    logout()
    dispatch(setCurrentUser([]))
    dispatch(changeLoginStatus(false))
  }
}


export const handleCacheData = () => {
  return (dispatch: any) => {
    // 缓存处理
    const currentUserCache = isAuthenticated()
    if (!!currentUserCache) {
      dispatch(setCurrentUser(JSON.parse(currentUserCache)))
      dispatch(changeLoginStatus(true))
    }
  }
}

const setCurrentUser = (data: any) => {
  return {
    type: types.SET_CURRENT_USER_INFO,
    payload: data
  }
}

const changeLoginStatus = (status: boolean = false) => {
  return {
    type: types.CHANGE_LOGIN_STATUS,
    payload: status
  }
}
