import React, {useMemo, useState} from 'react'
import {BackTop, Layout} from "antd";
import Header from './../components/layouts/Header'
import Footer from './../components/layouts/Footer'
import NavLeft from './../components/layouts/NavLeft'
import PageHeader from './../components/layouts/PageHeader'
import Divider from "../components/Divider";
import {connect} from "react-redux";
import {actionCreators} from '../pages/backend/Login/store'
import {bindActionCreators} from "redux";
import {getMenu} from "../utils/cache";

const {Content} = Layout
const {handleUserLogout} = actionCreators

const AdminLayout: React.FC = function (props: any) {

  const [collapsed, handleToggle] = useState<boolean>(false)
  const {children, currentUser, dispatch} = props

  const cbs = useMemo(function headerParams() {
    return bindActionCreators({
      handleUserLogout,
    }, dispatch)
  }, [])

  return (
    <Layout style={{minHeight: '100vh'}}>
      <NavLeft
        collapsed={collapsed}
        MenuConfig={getMenu()}
      />
      <Layout>
        <Header
          collapsed={collapsed}
          handleToggle={handleToggle}
          currentUser={currentUser}
          {...cbs}
        />
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            background: '#fff',
          }}
        >
          <PageHeader></PageHeader>
          <Divider dashed/>
          {children}
        </Content>
        <Footer/>
      </Layout>
      <BackTop/>
    </Layout>
  )
}
const mapStateToProps = (state: any) => ({
  currentUser: state.getIn(['Login', 'currentUser']),
})

const mapDispatchToProps = (dispatch: any) => {
  return {dispatch}
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
