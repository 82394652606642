import React from 'react'

import {Layout, Menu} from 'antd';

const {Footer} = Layout;
const WebHeader = () => {
  return (
    <Footer style={{textAlign: 'center', backgroundColor: 'rgb(255, 67, 67)', color: 'white'}}>
      <p>Copyright©南京云起信息科技有限公司 苏ICP备14016179号</p>
      <p>如果您对我们有任何建议，或者服务质量上的投诉，请发邮件至feedback@ehailuo.com，我们会及时作出回应。</p>
    </Footer>
  )
}
export default WebHeader