import {actionTypes as types} from './index'
import React from 'react'
import {post} from "../../../../components/axios";


export const queryTableListData = (params: any = {}) => {
  return (dispatch: Function) => {
    post('v1/user/index', params).then((data: any) => {
      const {data: list, page} = data
      dispatch(setPage(page))
      dispatch(setUserList(list))
    })
  }
}

export const handleQueryInfo = (id: number) => {
  return (dispatch: Function) => {
    post('/v1/user/info', {id}).then((data: any) => {
      dispatch(setUserInfo(data['data']))
    })
  }
}

export const handleAddUser = (params: any) => {
  return (dispatch: Function) => {
    post('/v1/user/register', params).then(() => {
      dispatch(queryTableListData())
    })
  }
}

export const handleEditUser = (params: any) => {
  return (dispatch: Function) => {
    post('/v1/user/update', params).then(() => {
      dispatch(queryTableListData())
    })
  }
}

export const handleDeleteUser = (id: number) => {
  return (dispatch: Function) => {
    post('v1/user/delete', {id}).then(() => {
      dispatch(queryTableListData())
    })
  }
}

const setUserList = (data: any) => {
  return {
    type: types.SET_USER_LIST_DATA,
    payload: data
  }
}
export const setUserInfo = (data: any) => {
  return {
    type: types.SET_USER_INFO,
    payload: data
  }
}
const setPage = (page: any) => {
  return {
    type: types.SET_PAGE,
    payload: page
  }
}
